.nav-main {
  background-color: var(--navbar-bg-color); }
  .nav-main .nav-link {
    color: var(--navbar-text-color);
    font-size: 0.875rem;
    padding: 0 0.9375rem;
    transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    height: 3.75rem; }
  .nav-main .nav-megamenu {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 999; }
    .nav-main .nav-megamenu:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.5rem;
      top: -0.5rem;
      left: 0; }
  .nav-main .dropdown-menu {
    right: 0;
    margin-top: 1px; }
    .nav-main .dropdown-menu:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.125rem;
      top: -0.125rem;
      left: 0; }
  .nav-main:hover .nav-link,
  .nav-main:focus-within .nav-link {
    opacity: 0.7; }
  .nav-main .nav-item.is-active > .nav-link,
  .nav-main .nav-item:focus-within .nav-link {
    opacity: 1;
    position: relative;
    background-color: var(--navbar-active-bg-color); }
  .nav-main .nav-item.is-nested.is-active > .nav-link:after {
    position: absolute;
    height: 0.125rem;
    background-color: currentColor;
    bottom: 0;
    left: 0;
    width: 100%; }
  .nav-main .nav-item.is-active > .dropdown-menu,
  .nav-main .nav-item.is-active > .nav-megamenu {
    display: block; }

.nav-megamenu {
  --megamenu-bg-color: var(--navbar-active-bg-color);
  --megamenu-link-color: var(--navbar-text-color);
  background-color: var(--megamenu-bg-color);
  color: var(--megamenu-link-color);
  padding: 2.375rem 0 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem; }
  .nav-megamenu__columns {
    columns: 3;
    --bs-gutter-x: 2rem;
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5); }
  .nav-megamenu__item {
    max-width: 18rem;
    display: block;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5); }
  .nav-megamenu__link {
    display: inline-block;
    color: var(--megamenu-link-color);
    text-decoration: none;
    margin-bottom: 2.375rem; }
    .nav-megamenu__link:hover, .nav-megamenu__link:focus {
      color: var(--megamenu-link-color);
      text-decoration: underline; }
